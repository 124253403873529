import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout/index";
import Portada from "../utils/Portada";
import Mobile from "../Assets/portada_servicios_mobile.webp";
import Desktop from "../Assets/portada_servicios_desktop.webp";
import Coclear from "../Components/Coclear";
import Help from "../Components/Help";
import HorizontalSlide from "../Components/HorizontalSlide";
import { animateScroll as scroll, scroller } from "react-scroll";

const Servicios = () => {
  // const [hasMounted, setHasMounted] = useState(false);

  const obtenerValorHash = () => {
    const hash = window.location.hash.slice(1); // Elimina el símbolo de almohadilla (#)
    return hash;
  };

  useEffect(() => {
    // setHasMounted(true);
    const hash = obtenerValorHash();

    if (hash) {
      // scroll.scrollTo("servicios");
      if (hash === "programas_apoyo") {
        scroller.scrollTo("help", {
          duration: 800,
          smooth: true,
          offset: 200,
        });
      } else {
        scroller.scrollTo("servicios", {
          duration: 800,
          smooth: true,
          offset: -100,
        });
      }
    }
  }, []);

  return (
    <Layout>
      <Portada imageDesktop={Desktop} imageMobile={Mobile} title="Servicios" />
      <Coclear />
      <HorizontalSlide />
      <Help />
    </Layout>
  );
};

export default Servicios;
